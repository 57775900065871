/**
 * @return {Boolean}
 */
export const investorHasSameResidentialAndMailingAddresses = () => {
  const investor = JSON.parse(localStorage.getItem('investor'))
  const {
    unitNumber,
    streetNumber,
    locality,
    postalCode,
    country,
    administrativeAreaLevel1,
    mailingUnitNumber,
    mailingStreetNumber,
    mailingLocality,
    mailingPostalCode,
    mailingCountry,
    mailingAdministrativeAreaLevel1
  } = investor
  return (
    unitNumber === mailingUnitNumber &&
    streetNumber === mailingStreetNumber &&
    locality === mailingLocality &&
    postalCode === mailingPostalCode &&
    country === mailingCountry &&
    administrativeAreaLevel1 === mailingAdministrativeAreaLevel1
  )
}
