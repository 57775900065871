<template>
  <div class="wallet-wrap">
    <div class="box plain-shadow-on-desktop">
      <div class="page-title has-text-centered">Fund Your Wallet</div>
      <div class="page-subtitle has-text-centered">Almost done! To start investing you will need to connect your bank account and add funds to your wallet.</div>
      <div class="is-flex is-justify-content-center is-align-items-center methods-box mt-5">
        <div :class="`card-wrap is-clickable ${eftSelected ? 'selected' : ''}`" @click="selectEftAction">
          <div class="method-card eft is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between">
            <div class="method-header has-text-centered">
              <div class="has-text-blue">ADD YOUR</div>
              <div class="has-text-blue">BANK ACCOUNT</div>
            </div>
            <img src="@assets/images/signup/wallet-setup-eft.png" class="eft" alt="method">
            <div>Tap to get started</div>
          </div>
        </div>
      </div>
      <div class="has-text-centered">
        <i class="fa fa-info-circle has-text-blue tooltip-icon is-clickable" aria-hidden="true" @click="showInstantFundsModalAction"></i>
      </div>
      <div class="hint has-text-centered has-text-blue">You will have $500 instantly available to invest after you add your bank and fund your wallet.</div>
      <div class="has-text-centered setup-later">
        <span class="has-text-primary is-clickable" @click="setupLater">Setup Later</span>
      </div>
    </div>
    <BottomButtons :step="3" :nextDisabled="!eftSelected" @goNext="goNext" @goBack="goBack"></BottomButtons>
    <Modal
      title="Are you sure you want to set this up later? We recommend to fund your account now so your experience is quicker later."
      text="You will need to add funds to your wallet before you can complete an investment in a property."
      :showModal="showModal"
      @cancelAction="showModal = false"
      @doContinue="continueSetupLater"
    ></Modal>
    <StandardModal
      :showModal="showInstantFundsModal"
      :closeModal="closeInstantFundsModalAction"
      title="Instant Funds ⚡️"
      buttonTitle="Done"
      readMoreUrl="https://www.addyinvest.com/2021/06/11/introducing-instant-credit/"
      >
      <div class="modal-text">
        <div class="mb-4">There is a $500 limit on instantly available funds with new deposits you make.
          This can be spread across multiple deposits or used all in one go. You can use this immediately
          on share purchases only. Your limit resets after your deposit is processed.</div>
        <div>If you deposit more than $500 or go over your instant fund limit, your funds will
          be available in your addy wallet within 7 business days.</div>
      </div>
    </StandardModal>
  </div>
</template>
<script>
import BottomButtons from '@components/button/bottom-buttons.vue'
import Modal from '@components/modal/continue-or-cancel-modal.vue'
import { getInvestorData } from '@api/signup'
import { mapState } from 'vuex'
import StandardModal from '@components/modal/standard-modal.vue'
import walletRoutes from '@utils/mixins/wallet-routes.js'
import { embeddedInIos } from '@utils/common-methods/common'
import { investorHasSameResidentialAndMailingAddresses } from '@utils/common-methods/investorOnboarding'

export default {
  mixins: [walletRoutes],
  components: {
    BottomButtons,
    Modal,
    StandardModal,
  },
  data() {
    return {
      eftSelected: false,
      showModal: false,
      investor: {},
      showInstantFundsModal: false,
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
  },
  created() {
    this.getInvestorInfo()
  },
  methods: {
    getInvestorInfo() {
      getInvestorData().then((data) => {
        this.investor = data
        localStorage.setItem('investor', JSON.stringify(data))
      })
    },
    selectEftAction() {
      this.eftSelected = true
    },
    goNext() {
      if (this.eftSelected) {
        if (embeddedInIos()) return window.webkit.messageHandlers.didLinkAccount.postMessage(true)
        this.mixpanel.track('gcr97rdb')
        this.toLinkBankPage()
      }
    },
    goBack() {
      if (investorHasSameResidentialAndMailingAddresses()) {
        this.$router.push('/signup/address/1?mailingIsSame=true')
      } else {
        this.$router.push('/signup/address/2')
      }
    },
    setupLater() {
      this.mixpanel.track('7besjc56')
      this.showModal = true
    },
    continueSetupLater() {
      if (embeddedInIos()) return window.webkit.messageHandlers.closeWebview.postMessage(true)
      this.$router.push('/dashboard')
    },
    showInstantFundsModalAction() {
      this.showInstantFundsModal = true
    },
    closeInstantFundsModalAction() {
      this.showInstantFundsModal = false
    },
  },
}
</script>
<style lang="scss" scoped>
.wallet-wrap {
  padding: 0 25px 140px;
  .box {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 26px;
    padding-bottom: 26px;
    box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
    .page-title {
      font-size: 32px;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .page-subtitle {
      font-size: 16px;
      line-height: 19px;
      padding: 0 12px;
    }
    .select-method {
      font-size: 16px;
      padding-top: 33px;
      padding-bottom: 22px;
    }
    .methods-box {
      padding: 0 2px;
      height: 190px;
      .card-wrap {
        border-radius: 12px;
        border: 1px solid #CACAC8;
        box-sizing: content-box;
        &.selected {
          opacity: 1;
          border: 2px solid #4A26AA;
          box-shadow: 0px 10px 25px -10px rgb(74 38 170 / 25%);
          box-sizing: content-box;
          .method-card.eft {
            margin-left: -1px;
          }
        }
        &.not-selected {
          opacity: 0.75;
        }
        .method-card {
          width: 141px;
          height: 168px;
          padding: 16px 17px 13px;
          .method-header {
            font-weight: bold;
            font-size: 16px;
            line-height: 1.2rem;
          }
          img.eft {
            width: 47px;
          }
        }
      }
    }
    .hint {
      font-size: 16px;
      line-height: 19px;
      padding: 22px 0 8px;
    }
    .tooltip-icon {
      font-size: 1rem;
    }
    .setup-later {
      font-size: 16px;
      line-height: 19px;
      padding-bottom: 6px;
    }
  }
}
</style>
