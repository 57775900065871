<template>
  <div class="bottom-buttons is-flex is-align-items-center is-justify-content-space-between">
    <span
      :class="`button-back is-clickable has-text-primary has-text-weight-bold ${backButtonVisible ? '' : 'is-invisible'}`"
      data-testid="back-btn"
      @click="goBack"
    >{{backButtonText}}</span>
    <StepIndicator :step="step" v-if="showSteps"></StepIndicator>
    <b-button
      type="has-background-primary"
      class="is-medium-height button-next has-text-weight-bold"
      data-testid="next-btn"
      :disabled="nextDisabled"
      :loading="loading"
      data-cy="next-btn"
      rounded
      @click="goNext"
    >{{nextButtonText}}</b-button>
  </div>
</template>
<script>
import StepIndicator from '@views/signup/StepIndicator.vue'

export default {
  components: {
    StepIndicator,
  },
  props: {
    backButtonVisible: {
      type: Boolean,
      default: true
    },
    nextDisabled: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 1
    },
    showSteps: {
      type: Boolean,
      default: true
    },
    nextButtonText: {
      type: String,
      default: 'Next'
    },
    backButtonText: {
      type: String,
      default: 'Back'
    },
  },
  methods: {
    goBack() {
      this.$emit('goBack')
    },
    goNext() {
      this.$emit('goNext')
    },
  }
}
</script>
<style lang="scss" scoped>
.bottom-buttons {
  .button-back {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 16px;
    padding-left: 18px;
  }
  .button-next {
    min-width: 94px;
  }
}
</style>
