<template>
  <div class="step-indicator is-flex">
    <div v-for="(item, index) in steps" :key="index" :class="item ? 'is-active has-background-blue' : 'not-active'"></div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1
    }
  },
  computed: {
    steps() {
      const arr = [0, 0]
      arr.splice(this.step - 1, 0, 1)
      return arr
    }
  }
}
</script>
<style lang="scss" scoped>
.step-indicator {
  padding-left: 20px;
  > div:not(:last-of-type) {
    margin-right: 5.4px;
  }
  div.is-active {
    width: 16px;
    height: 6px;
    border-radius: 3px;
  }
  div.not-active {
    width: 6px;
    height: 6px;
    background-color: #F0EEF8;
    border-radius: 3px;
  }
}
</style>
