




































import Vue from 'vue'

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    showModal: {
      type: Boolean,
      default: false
    },
    continueButtonText: {
      type: String,
      default: 'Continue'
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },
    hasCloseIcon: {
      type: Boolean,
      default: true
    },
    hasClickableBackground: {
      type: Boolean,
      default: true
    },
    hasCancelButtonOnLeft: {
      type: Boolean,
      default: false
    },
    continueButtonDisabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isActive: false as boolean,
      isLoading: false as boolean,
    }
  },
  created() {
    this.isActive = this.showModal
  },
  watch: {
    showModal(newValue: boolean): void {
      this.isActive = newValue
      this.isLoading = false
    }
  },
  methods: {
    closeModal(): void {
      if (!this.hasCloseIcon || !this.hasClickableBackground) return
      this.cancelAction()
    },
    cancelAction(): void {
      this.isActive = false
      this.$emit('cancelAction')
    },
    doContinue(): void {
      this.isLoading = true
      this.$emit('doContinue')
    },
  }
})
