import { getBankAccountStatus } from '@api/wallet'

const walletRoutes = {
  data() {
    return {
      linkedAccountStatus: false,
    }
  },
  created() {
    this.walletRoutesInit()
  },
  methods: {
    walletRoutesInit() {
      getBankAccountStatus().then((data) => {
        if (data.success) this.linkedAccountStatus = data.data.status
      })
    },
    toAddFundsPage() {
      // if (!inAvailableRegion()) return this.toNotInRegionPage('Fund wallet')
      // if (!this.linkedAccountStatus) return this.$router.push('/wallet/accounts/new')
      this.$router.push('/wallet/options')
    },
    toEftAddFundsPage() {
      if (this.linkedAccountStatus) return this.$router.push('/wallet/funds')
      this.toLinkBankPage()
    },
    toLinkBankPage() {
      // if (!inAvailableRegion()) return this.toNotInRegionPage('Add new bank')
      this.$router.push('/wallet/accounts/new')
    },
    toNotInRegionPage(title) {
      this.$router.push({
        name: 'regionNotAvailable',
        query: { title }
      })
    },
  }
}
export default walletRoutes
