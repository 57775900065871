import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * get the Plaid link token
 * @return {Promise}
 */
export const getPlaidLinkToken = () => {
  return axios.get(getImbyRoute('/api/v1/wallet/accounts/plaid_token'))
}

/**
 * get bank account info from PLAID
 * @param {Object} payload plaid_token, plaid_account_id, plaid_link_session_id
 * @return {Promise}
 */
export const getBankAccountInfoFromPlaid = (payload) => {
  return axios.post(getImbyRoute('/api/v1/wallet/accounts/connect_through_plaid'), { eft_account: payload }, { useMask: true })
}

/**
 * connect account
 * @param {Object} payload account_name, transit_number, routing_number, account_number, plaid_account_id, verification_status, verification_image, is_personal_account
 * @return {Promise}
 */
export const connectAccount = (payload) => {
  return axios.post(getImbyRoute('/api/v1/wallet/accounts'), payload)
}

/**
 * get connected accounts
 * @return {Promise}
 */
export const getConnectedAccounts = () => {
  return axios.get(getImbyRoute('/api/v1/wallet/accounts/account_list'))
}

/**
 * get paginated transactions
 * @param {Object} payload page, limit, country
 * @return {Promise}
 */
export const getPaginatedTransactions = (payload) => {
  return axios.get(getImbyRoute(`/api/v1/wallet/transactions`), { params: payload })
}

/**
 * remove connected account
 * @param {String} hashid
 * @return {Promise}
 */
export const removeConnectedAccount = (hashid) => {
  return axios.delete(getImbyRoute(`/api/v1/wallet/accounts?id=${hashid}`))
}

/**
 * fetch for wallet details
 * @return {Promise}
 */
export const getWalletDetails = () => {
  return axios.get(getImbyRoute('/api/v1/wallet/details'))
}

/**
 * add funds
 * @param {Number} amount
 * @param {String} id
 * @param {String} from
 * @return {Promise}
 */
export const addFundsToWallet = (amount, id, from = null) => {
  return axios.post(getImbyRoute('/api/v1/wallet/add_funds'), { from, coins_purchased: amount, eft_account_id: id })
}

/**
 * withdraw funds
 * @param {Number} amount
 * @param {String} id
 * @return {Promise}
 */
export const withdrawFunds = (amount, id) => {
  return axios.post(getImbyRoute('/api/v1/wallet/withdraw_funds'), { withdrawal_amount: amount, eft_account_id: id })
}

/**
 * check if user can add fund to wallet
 * @return {Promise}
 */
export const getBankAccountStatus = () => {
  return axios.get(getImbyRoute('/api/v1/wallet/linked_bank_account_status'))
}

/**
 * cancel coin order
 * @param {String} hashid
 * @return {Promise}
 */
export const cancelCoinOrder = (hashid) => {
  return axios.put(getImbyRoute('/api/v1/wallet/cancel_coin_order'), { hashid: hashid })
}

/**
 * cancel withdrawal
 * @param {String} hashid
 * @return {Promise}
 */
export const cancelWithdrawal = (hashid) => {
  return axios.put(getImbyRoute('/api/v1/wallet/cancel_withdrawal'), { hashid: hashid })
}

/**
 * submit I ACCEPT timestamp for US fund transfer agreement
 * @return {Promise}
 */
export const acceptUsFundTransferAgreement = () => {
  return axios.put(getImbyRoute('/api/v1/wallet/accept_us_fund_transfer_agreement'))
}
